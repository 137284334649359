export default {
  setTickersType(state, payload) {
    state.tickersType = payload;
  },
  setQuotes(state, payload) {
    state.quotes = payload;
  },
  setCurrencyExchangeRate(state, payload) {
    state.currencyExchangeRate = payload;
  },
  setCEIData(state, payload) {
    state.CEIData = payload;
  },
  setCEIlastSyncDate(state, payload) {
    state.CEIlastSyncDate = payload;
  },
  setContributionAmount(state, payload) {
    state.contributionAmount = payload;
  }
};
