import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC2Rpr1lMA-x9Vx3Lj5lBK6TkRZntbUoDM",
  authDomain: "my-pilas-99328.firebaseapp.com",
  projectId: "my-pilas-99328",
  storageBucket: "my-pilas-99328.appspot.com",
  messagingSenderId: "263675575358",
  appId: "1:263675575358:web:d2f36a7fd2febd2945656f"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
// firebase.firestore().settings({
//   cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
// });

// firebase
//   .firestore()
//   .enablePersistence({ experimentalTabSynchronization: true });
export default firebaseApp;
