import Vue from "vue";
import Vuex from "vuex";

import Users from "./modules/Users";
import Shared from "./modules/Shared";
import Tickers from "./modules/Tickers";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const modules = {
  Users,
  Shared,
  Tickers
};

const persistedStates = [
  "Users/setCurrentUser",
  "Tickers/setCEIData",
  "Tickers/setCEIlastSyncDate",
  "Tickers/setTickersType",
  "Tickers/setCurrencyExchangeRate",
  "Tickers/setQuotes"
];

const filterPersistedState = store => {
  const ok = persistedStates.indexOf(store.type) >= 0;
  return ok;
};

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      filter: filterPersistedState
    })
  ],
  strict: false,
  modules: modules
});
