import Vue from "vue";
import App from "./App.vue";
import VueGoogleCharts from "vue-google-charts";
import VueGtag from "vue-gtag";

import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./quasar";

Vue.config.productionTip = false;

Vue.use(VueGoogleCharts);
Vue.use(
  VueGtag,
  {
    config: { id: "UA-59365224-1" },
    appName: "MyPilas",
    pageTrackerScreenviewEnabled: true
  },
  router
);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
