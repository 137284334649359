import firebase from "firebase/app";
import "firebase/firestore";
// eslint-disable-next-line no-unused-vars
import { parseISO, isSunday, isSaturday, subDays, format } from "date-fns";
import axios from "axios";

import { isAuthenticated } from "@/services/Auth";
import * as httpFb from "@/services/httpFB";

const db = firebase.firestore();

const debug = process.env.NODE_ENV !== "production";

export default {
  async getTickersType({ commit }) {
    if (debug) console.log("Store Tickers - Action - getTickersType");
    const currentUser = isAuthenticated();
    const { uid } = currentUser;

    try {
      const responseFB = await db
        .collection("users")
        .doc(uid)
        .collection("tickersType")
        .get();

      const tickersType = [];
      responseFB.forEach(doc => {
        tickersType.push({ ...doc.data(), id: doc.id });
      });
      commit("setTickersType", tickersType);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async addTickerType({ dispatch }, tickerType) {
    if (debug)
      console.log("Store Tickers - Action - addTickerType", { tickerType });
    const currentUser = isAuthenticated();
    const { uid } = currentUser;
    try {
      await db
        .collection("users")
        .doc(uid)
        .collection("tickersType")
        .add(tickerType);
      dispatch("getTickersType");
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async updateTickerType({ dispatch }, tickerType) {
    if (debug)
      console.log("Store Tickers - Action - updateTickerType", { tickerType });

    const currentUser = isAuthenticated();
    const { uid } = currentUser;
    const payload = { ...tickerType };
    delete payload.id;
    try {
      await db
        .collection("users")
        .doc(uid)
        .collection("tickersType")
        .doc(tickerType.id)
        .set(payload);
      dispatch("getTickersType");
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async deleteTickerType({ dispatch }, tickerType) {
    if (debug)
      console.log("Store Tickers - Action - deleteTickerType", { tickerType });

    const currentUser = isAuthenticated();
    const { uid } = currentUser;
    try {
      await db
        .collection("users")
        .doc(uid)
        .collection("tickersType")
        .doc(tickerType.id)
        .delete();
      dispatch("getTickersType");
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async updateTickerTransactions({ dispatch }, payload) {
    if (debug)
      console.log("Store Tickers - Action - updateTickerTransactions", {
        payload
      });
    const currentUser = isAuthenticated();
    const { uid } = currentUser;
    const { transactions, tickerTypeId, ticker, insertNewTicker } = payload;

    try {
      const responseFB = await db
        .collection("users")
        .doc(uid)
        .collection("tickersType")
        .doc(tickerTypeId)
        .get();

      const tickerType = { ...responseFB.data(), id: responseFB.id };
      const tickers = tickerType.tickers;
      console.log({ tickers });
      const position = tickers
        ? tickers.findIndex(item => item.ticker === ticker)
        : -1;

      if (position >= 0) {
        tickerType.tickers[position].transactions = transactions;
      } else {
        if (insertNewTicker) {
          if (!tickerType.tickers) {
            tickerType.tickers = [];
          }
          tickerType.tickers.push({ ...insertNewTicker, transactions });
        } else {
          throw new Error(`Ativo ${ticker} não encontrado`);
        }
      }
      await dispatch("updateTickerType", tickerType);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async updateQuotes({ getters, state, commit }) {
    if (debug) console.log("Store Tickers - Action - updateQuotes");
    const { tickers } = getters;
    const today = format(new Date(), "yyyyMMdd");
    let { quotes } = state;
    if (!quotes) {
      quotes = [];
    }
    if (quotes.findIndex(quote => quote.date === today) < 0) {
      quotes = [];
      const responce = await db
        .collection("quotes")
        .where("date", "==", today)
        .get();
      responce.docs.forEach(doc => {
        quotes.push({ ...doc.data });
      });
    }
    const promisses = tickers.map(ticker => {
      return new Promise(resolve => {
        (async () => {
          let price = 0;
          if (quotes) {
            const position = quotes.findIndex(
              quote => quote.date === today && quote.ticker === ticker.ticker
            );
            if (position >= 0) {
              price = quotes[position].price;
            }
          }
          if (price <= 0) {
            try {
              const responce = await db
                .collection("quotes")
                .where("ticker", "==", ticker.ticker)
                .where("date", "==", today)
                .get();
              if (!responce.empty) {
                price = responce.docs[0].data().price;
                const position = quotes.findIndex(
                  quote => quote.ticker === ticker.ticker
                );
                if (position > 0) {
                  quotes[position].date = today;
                  quotes[position].price = price;
                } else {
                  quotes.push({ ticker: ticker.ticker, date: today, price });
                }
              }
            } catch (error) {
              console.error(error.message);
            }
          }
          if (price <= 0) {
            let endPoint = "";
            if (ticker.itIsCrypto) {
              endPoint = `crypto/${ticker.ticker}`;
            } else {
              endPoint = `stock/${ticker.ticker}`;
              if (ticker.tickerIsOnB3) {
                endPoint += ".sa";
              }
            }
            try {
              if (debug)
                console.time(
                  `Store Tickers - Action - updateTickerTransactions - Get ticker ${ticker.ticker} quote on api`
                );
              const responce = await httpFb.get(endPoint);
              price = responce.data.price;
            } catch (error) {
              console.error(error);
            }
            if (debug)
              console.timeEnd(
                `Store Tickers - Action - updateTickerTransactions - Get ticker ${ticker.ticker} quote on api`
              );
            if (price > 0) {
              await db
                .collection("quotes")
                .add({ ticker: ticker.ticker, date: today, price });
            }
          }
          resolve({ ticker: ticker.ticker, date: today, price });
        })();
      });
    });
    quotes = await Promise.all(promisses);
    commit("setQuotes", quotes);
  },
  async getCurrencyExchangeRateByDate({ commit }, payload) {
    if (debug)
      console.log("Store Tickers - Action - getCurrencyExchangeRateByDate", {
        payload
      });
    let date = null;
    let currentDay = false;
    try {
      if (payload && payload.date) {
        date = parseISO(payload.date);
      } else {
        date = new Date();
        currentDay = true;
      }

      while (isSaturday(date) || isSunday(date)) {
        date = subDays(date, 1);
      }
      let response = { data: { value: [] } };

      while (response.data.value.length <= 0) {
        response = await axios.get(
          `https://olinda.bcb.gov.br/olinda/servico/PTAX/versao/v1/odata/CotacaoDolarDia(dataCotacao=@dataCotacao)?@dataCotacao=%27${format(
            date,
            "MM-dd-yyyy"
          )}%27&$top=100&$format=json&$select=cotacaoCompra`
        );
        date = subDays(date, 1);
      }
      const rate = response.data.value[0].cotacaoCompra;
      if (currentDay) {
        commit("setCurrencyExchangeRate", rate);
      }
      return rate;
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async getCEI({ commit }, payload) {
    const currentUser = isAuthenticated();
    const { uid } = currentUser;
    const CEIlastSyncDate = format(new Date(), "yyyyMMdd");

    if (
      currentUser.CEIlastSyncDate &&
      currentUser.CEIlastSyncDate >= CEIlastSyncDate
    ) {
      commit("setCEIData", currentUser.CEIData);
      commit("setCEIlastSyncDate", currentUser.CEIlastSyncDate);
      return;
    }

    if (!payload) {
      return;
    }

    try {
      const responce = await httpFb.post("cei", payload);
      const CEIData = responce.data;
      await db
        .collection("users")
        .doc(uid)
        .update({ CEIData, CEIlastSyncDate });
      commit("setCEIData", CEIData);
      commit("setCEIlastSyncDate", CEIlastSyncDate);
    } catch (error) {
      console.error({ error });
      throw new Error(
        "Falha ao buscar dados na CEI, confira seu CPF e/ou senha, ou tente novamente mais tarde."
      );
    }
  }
};
