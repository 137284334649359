import axios from "axios";
import store from "../store";
const instance = axios.create({
  timeout: 30000,
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://us-central1-my-pilas-99328.cloudfunctions.net/api"
      : "http://127.0.0.1:5001/my-pilas-99328/us-central1/api"
});
//https://us-central1-my-pilas-99328.cloudfunctions.net/api
//http://127.0.0.1:5001/my-pilas-99328/us-central1/api
export const get = async endPoint => {
  const token = store.state.Users.currentUser.token;
  instance.defaults.headers.common.authorization = token;
  return instance.get(endPoint);
};

export const post = async (endPoint, payload) => {
  const token = store.state.Users.currentUser.token;
  instance.defaults.headers.common.authorization = token;
  return instance.post(endPoint, payload);
};

export const put = async (endPoint, payload) => {
  const token = store.state.Users.currentUser.token;
  instance.defaults.headers.common.authorization = token;
  return instance.put(endPoint, payload);
};

export const del = async endPoint => {
  const token = store.state.Users.currentUser.token;
  instance.defaults.headers.common.authorization = token;
  return instance.delete(endPoint);
};
