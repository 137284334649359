import { orderBy, round } from "lodash";
import { format } from "date-fns";
export default {
  tickers(state) {
    const today = format(new Date(), "yyyyMMdd");
    const { quotes, currencyExchangeRate, contributionAmount } = state;
    const typeTotalWeight = state.tickersType.reduce(
      (acc, item) => (acc += item.weight),
      0
    );
    const totalizers = state.tickersType.reduce((acc, tickerType) => {
      let position = acc.findIndex(item => item.id == tickerType.id);
      if (position >= 0) {
        acc[position].totalWeight += tickerType.weight;
      } else {
        acc.push({
          id: tickerType.id,
          totalWeight: tickerType.weight,
          totalTickersWeight: 0
        });
        position = acc.length - 1;
      }
      acc[position].participation = acc[position].totalWeight / typeTotalWeight;

      if (tickerType.tickers) {
        Object.values(tickerType.tickers).forEach(ticker => {
          const position = acc.findIndex(item => item.id == tickerType.id);
          if (position >= 0) {
            acc[position].totalTickersWeight += ticker.weight;
          } else {
            acc.push({
              id: tickerType.id,
              totalTickersWeight: ticker.weight
            });
          }
        });
      }

      return acc;
    }, []);

    let tickers = state.tickersType.reduce((acc, tickerType) => {
      if (tickerType.tickers) {
        const totalizer = totalizers.find(item => item.id === tickerType.id);
        Object.values(tickerType.tickers).forEach(ticker => {
          let transactions = ticker.transactions ? ticker.transactions : [];
          transactions = orderBy(transactions, ["date"], ["asc"]);
          const quantity = transactions.reduce((acc, transaction) => {
            if (transaction.operation === "add") {
              acc += transaction.quantity;
            } else {
              acc -= transaction.quantity;
            }
            return acc;
          }, 0);
          let lastPrice = 0;
          let lastPurchase = "";
          let originalAveragePrice = 0;
          let originalAveragePriceBRL = 0;
          let purchaseQuote = 0;

          const totalAmountPurchased = transactions.reduce(
            (acc, transaction) => {
              let price = transaction.price;
              if (!tickerType.itIsCrypto && !tickerType.tickerIsOnB3) {
                price = price * transaction.exchangeRate;
                if (transaction.operation === "add") {
                  originalAveragePrice +=
                    transaction.quantity * transaction.price;
                  originalAveragePriceBRL += transaction.quantity * price;
                  lastPrice = price;
                  lastPurchase = transaction.date;
                } else {
                  originalAveragePrice -=
                    transaction.quantity * transaction.price;
                  originalAveragePriceBRL -= transaction.quantity * price;
                }
              }
              if (transaction.operation === "add") {
                acc += transaction.quantity * price;
                lastPrice = price;
                lastPurchase = transaction.date;
              } else {
                acc -= transaction.quantity * price;
              }
              return acc;
            },
            0
          );
          if (quantity > 0) {
            originalAveragePriceBRL = originalAveragePriceBRL / quantity;
            originalAveragePrice = originalAveragePrice / quantity;
            if (originalAveragePrice > 0)
              purchaseQuote = originalAveragePriceBRL / originalAveragePrice;
          }

          let currentQuote = 0;
          let currentEquity = 0;
          let profitOrLossValue = 0;
          let profitOrLossPercentage = 0;
          let exchangeVariation = 0;
          let tickerValuation = 0;

          const averagePrice =
            quantity > 0 ? totalAmountPurchased / quantity : 0;
          if (quotes) {
            const quoteFind = quotes.find(
              quote => quote.date === today && quote.ticker === ticker.ticker
            );
            if (quoteFind && quoteFind.price > 0) {
              currentQuote = quoteFind.price;
              if (!tickerType.itIsCrypto && !tickerType.tickerIsOnB3) {
                tickerValuation = currentQuote - originalAveragePrice;
                exchangeVariation = originalAveragePrice * currencyExchangeRate;
                exchangeVariation -= originalAveragePrice * purchaseQuote;
                exchangeVariation = round(exchangeVariation, 2);
                currentQuote = currentQuote * currencyExchangeRate;
              }
              currentEquity = currentQuote * quantity;
              profitOrLossValue = currentEquity - totalAmountPurchased;
              profitOrLossPercentage =
                totalAmountPurchased > 0
                  ? (profitOrLossValue / totalAmountPurchased) * 100
                  : 0;
              tickerValuation = profitOrLossValue - exchangeVariation;
            }
          }

          acc.push({
            ...ticker,
            tickerTypeTitle: tickerType.title,
            tickerTypeId: tickerType.id,
            tickerTypeWeight: tickerType.weight,
            decimalPlaces: tickerType.decimalPlaces,
            tickerWeight: ticker.weight,
            itIsCrypto: tickerType.itIsCrypto,
            tickerIsOnB3: tickerType.tickerIsOnB3,
            transactions,
            quantity,
            averagePrice,
            totalAmountPurchased,
            lastPrice,
            lastPurchase,
            currentQuote,
            currentEquity,
            exchangeVariation,
            tickerValuation,
            profitOrLossValue,
            profitOrLossPercentage,
            currentPercentage: 0,
            targetPercentage:
              (ticker.weight / totalizer.totalTickersWeight) *
              100 *
              totalizer.participation,
            differencePercentage: 0,
            contributionQuantity: 0,
            contributionValue: 0,
            predictedEquity: 0,
            predictedParticipation: 0
          });
        });
      }
      return acc;
    }, []);

    const totalEquity = tickers.reduce(
      (acc, ticker) => (acc += ticker.currentEquity),
      0
    );

    tickers = tickers.map(ticker => {
      const currentPercentage = (ticker.currentEquity / totalEquity) * 100;
      const differencePercentage = currentPercentage - ticker.targetPercentage;

      return {
        ...ticker,
        currentPercentage,
        differencePercentage
      };
    });

    tickers = orderBy(tickers, ["differencePercentage"], ["asc"]);

    const baseValueContribution = round(totalEquity, 2) + contributionAmount;
    let accumulatedContributionValue = 0;
    tickers = tickers.map(ticker => {
      if (round(accumulatedContributionValue, 2) >= contributionAmount) {
        const predictedEquity = round(
          ticker.currentEquity + ticker.contributionValue,
          2
        );

        return { ...ticker, predictedEquity };
      }
      const targetValue =
        (baseValueContribution * ticker.targetPercentage) / 100;
      const missingValue = targetValue - ticker.currentEquity;

      let contributionQuantity =
        missingValue > 0
          ? round(missingValue / ticker.currentQuote, ticker.decimalPlaces)
          : 0;
      let contributionValue = round(
        contributionQuantity * ticker.currentQuote,
        2
      );
      accumulatedContributionValue += round(contributionValue, 2);
      if (round(accumulatedContributionValue, 2) > contributionAmount) {
        accumulatedContributionValue -= round(contributionValue, 2);
        const contributionBalance =
          contributionAmount - round(accumulatedContributionValue, 2);

        if (ticker.decimalPlaces > 0) {
          contributionQuantity = round(
            contributionBalance / ticker.currentQuote,
            ticker.decimalPlaces
          );
        } else {
          contributionQuantity = parseFloat(
            parseInt(contributionBalance / ticker.currentQuote)
          );
        }
        contributionValue = round(
          contributionQuantity * ticker.currentQuote,
          2
        );
        accumulatedContributionValue += round(contributionValue, 2);
      }

      const predictedEquity = round(
        ticker.currentEquity + contributionValue,
        2
      );
      return {
        ...ticker,
        targetValue,
        missingValue,
        contributionQuantity,
        contributionValue,
        predictedEquity
      };
    });

    const predictedTtotaleEquity = round(
      tickers.reduce((acc, ticker) => (acc += ticker.predictedEquity), 0),
      2
    );

    tickers = tickers.map(ticker => {
      const predictedParticipation = round(
        (ticker.predictedEquity / predictedTtotaleEquity) * 100,
        2
      );
      return {
        ...ticker,
        predictedParticipation
      };
    });

    return tickers;
  },

  SynchronizeCEIData(state, getters) {
    const tickers = getters.tickers;
    if (!state.CEIData) {
      return [];
    }
    const data = state.CEIData.map(data => {
      const tickerFind = tickers.find(
        ticker => ticker.ticker === cleanTicker(data.code)
      );
      if (tickerFind) {
        return {
          ticker: cleanTicker(data.code),
          quantity: data.quantity,
          lastDateTransaction: data.lastDateTransaction,
          transactions: orderBy(
            data.transactions.map(item => {
              return {
                ...item,
                operation: item.operation === "C" ? "add" : "remove"
              };
            }),
            ["date"],
            ["asc"]
          ),
          tickerTypeId: tickerFind.tickerTypeId,
          tickerTypeTitle: tickerFind.tickerTypeTitle,
          transactionsBase: tickerFind.transactions,
          quantityBase: tickerFind.quantity,
          lastPurchaseBase: tickerFind.lastPurchase
        };
      } else {
        return {
          ticker: cleanTicker(data.code),
          quantity: data.quantity,
          lastDateTransaction: data.lastDateTransaction,
          transactions: orderBy(
            data.transactions.map(item => {
              return {
                ...item,
                operation: item.operation === "C" ? "add" : "remove"
              };
            }),
            ["date"],
            ["asc"]
          ),
          tickerTypeId: "",
          tickerTypeTitle: "",
          transactionsBase: [],
          quantityBase: 0,
          lastPurchaseBase: ""
        };
      }
    });
    return data;
  }
};

const cleanTicker = ticker => {
  let result = ticker;
  const lastLetter = ticker.charAt(ticker.length - 1);
  if (lastLetter === "F") result = ticker.substr(0, ticker.length - 1);
  return result;
};
