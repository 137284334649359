<template>
  <q-layout view="hHh lpR fFf">
    <q-header reveal elevated class="bg-grey-7 text-white" height-hint="98">
      <q-toolbar>
        <q-toolbar-title>
          <div class="row justify-between">
            <span class="col-grown text-weight-light"
              >My <span class="text-weight-bolder">Pilas</span></span
            >
            <div class="col-grown column text-right">
              <span v-if="userNickName" class="col text-caption"
                >Olá {{ userNickName }}</span
              >
              <span v-if="userNickName" class="col text-caption text-bold"
                >Cotação Atual Dolar
                {{ formatString(currencyExchangeRate, "currency", 4) }}</span
              >
            </div>
          </div>
        </q-toolbar-title>
      </q-toolbar>

      <q-tabs v-if="isAuthenticated" align="justify">
        <q-route-tab to="/dashboard" label="Dashboard" />
        <q-route-tab to="/goals" label="Metas" />
        <q-route-tab to="/portfolio" label="Minha Carteira" />
        <q-tab label="Sair" @click="exit" />
      </q-tabs>
    </q-header>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { isAuthenticated, signOut } from "@/services/Auth";
import { mapState } from "vuex";
import formatString from "./helpers/formatString";

export default {
  data() {
    return {
      left: false
    };
  },
  computed: {
    ...mapState("Tickers", ["currencyExchangeRate"]),
    isAuthenticated() {
      return isAuthenticated();
    },
    userNickName() {
      if (this.isAuthenticated) {
        return this.isAuthenticated.nickName;
      }
      return "";
    }
  },
  methods: {
    formatString,
    exit() {
      this.$q
        .dialog({
          title: "Confirmação",
          message: "Deseja realmente encerrar a sua sessão?",
          ok: {
            push: true,
            color: "positive",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "negative",
            label: "Não"
          },
          persistent: true
        })
        .onOk(() => {
          signOut();
        });
    }
  },
  watch: {
    isAuthenticated(newValue) {
      if (!newValue) {
        this.$router.push({ name: "SignIn" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.q-page-container {
  background: #f5f5f5;
  height: 100vh;
  width: 100vw;
}
</style>
