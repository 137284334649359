import firebase from "@/services/Firebase";
import firebaseAuth from "firebase/app";
import "firebase/auth";
import store from "../store";
const { commit } = store;

const auth = firebase.auth();
const db = firebase.firestore();

const isAuthenticated = () => {
  const currentUser = store.state.Users.currentUser;
  return currentUser;
};

const signUp = async payload => {
  const { email, password, nickName } = payload;
  try {
    const response = await auth.createUserWithEmailAndPassword(email, password);
    const { uid } = response.user;
    await auth.signInWithEmailAndPassword(email, password);

    db.collection("users")
      .doc(uid)
      .set({ nickName, email, uid });

    await signIn({ email, password });

    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

const signIn = async payload => {
  const { email, password } = payload;
  try {
    await auth.setPersistence(firebaseAuth.auth.Auth.Persistence.SESSION);
    const response = await auth.signInWithEmailAndPassword(email, password);

    const user = await db
      .collection("users")
      .doc(response.user.uid)
      .get();

    const token = await firebase.auth().currentUser.getIdToken(true);

    commit("Users/setCurrentUser", { ...user.data(), token });

    return user;
  } catch (error) {
    throw new Error(error.code);
  }
};

const signOut = async () => {
  try {
    await auth.signOut();
    commit("Users/setCurrentUser", null);
    commit("Tickers/setCEIData", null);
    commit("Tickers/setTickersType", null);
    commit("Tickers/setCurrencyExchangeRate", null);
    commit("Tickers/setQuotes", null);
  } catch (error) {
    throw new Error(error.message);
  }
};

const resetPassword = async email => {
  try {
    await auth.sendPasswordResetEmail(email);
  } catch (error) {
    throw new Error(error.message);
  }
};

export { isAuthenticated, signIn, signOut, signUp, resetPassword };
