import Vue from "vue";
import VueRouter from "vue-router";

import { isAuthenticated } from "../services/Auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { requiresAuth: true },
    component: () => import("../views/Home")
  },
  {
    path: "/signin",
    name: "SignIn",
    meta: { requiresAuth: false },
    component: () => import("../views/SignIn")
  },
  {
    path: "/cadastrar",
    name: "SignUp",
    meta: { requiresAuth: false },
    component: () => import("../views/SignUp")
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { requiresAuth: true },
    component: () => import("../views/Dashboard")
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    meta: { requiresAuth: true },
    component: () => import("../views/Portfolio")
  },
  {
    path: "/goals",
    name: "Goals",
    meta: { requiresAuth: true },
    component: () => import("../views/Goals")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (!isAuthenticated() && to.meta.requiresAuth) {
    next({ name: "SignIn" });
  } else next();
});

export default router;
