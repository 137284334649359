import { format, parseISO } from "date-fns";

export default function formatString(
  value,
  type = "string",
  decimals = 2,
  sufix = "",
  currency = "BRL"
) {
  if (type.toLocaleLowerCase() === "currency") {
    return (
      new Intl.NumberFormat("pt-BR", {
        localeMatcher: "best fit",
        style: "currency",
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
        currency
      })
        .format(value)
        .split("")
        .map(x => {
          if (x.charCodeAt(0) === 160) {
            return " ";
          } else {
            return x;
          }
        })
        .join("") + sufix
    );
  }
  if (type.toLocaleLowerCase() === "number") {
    return (
      Intl.NumberFormat("pt-BR", {
        style: "decimal",
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      })
        .format(value)
        .split("")
        .map(x => {
          if (x.charCodeAt(0) === 160) {
            return " ";
          } else {
            return x;
          }
        })
        .join("") + sufix
    );
  }
  if (type.toLocaleLowerCase() == "date") {
    if (value === "") {
      return "";
    }
    return format(parseISO(value), "dd/MM/yyyy");
  }
  return value + sufix;
}
